<template>
  <div class="card card-gradients">
    <div class="card__section" v-html="contentItem.text"></div>
    <div
      class="card__section card-gradients__gradient"
      v-for="(item, index) in contentItem.items"
      :key="index"
    >
      <div
        class="card-gradients__color has-border"
        :style="{ background: gradient(item) }"
      ></div>
      <div class="grid-x align-justify">
        <div
          class="cell shrink"
          v-for="(color, sub_index) in item.colors"
          :key="sub_index"
        >
          <p>{{ color.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardColorGradient',
  props: ['contentItem'],
  methods: {
    gradient: function (item) {
      let colors = 'linear-gradient(to right'
      item.colors.forEach(el => {
        colors += ',#' + el.value
      })
      colors += ')'
      return colors
    }
  }
}
</script>

<style scoped lang="scss">
.card-gradients__gradient {
  border-top: 1px solid $ui-line-color;
}
</style>
