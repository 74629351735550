<template>
  <div class="grid-x grid-margin-x large-up-2 card-list--large-margin">
    <div
      class="cell card card__content"
      v-for="(item, index) in contentItem.items"
      :key="index"
      :class="[item.size, item.type === 'image' ? 'cover-bg' : '']"
      :style="itemBackground(item)"
    >
      <div v-html="item.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardList',
  props: ['contentItem'],
  methods: {
    itemBackground (item) {
      if (item.type === 'image') {
        return 'background-image:url(\'' + item.image + '\');'
      } else {
        return 'background-color:#' + item.color + ';'
      }
    }
  }
}
</script>
