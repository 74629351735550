<template>
  <div>
    <h2 class="medium">{{ $t("assets.recent_uploaded") }}</h2>
    <div class="spacer--30"></div>
    <div class="grid-x grid-margin-x grid card-list">
      <router-link
        v-for="(asset, index) in assets"
        :key="index"
        :to="{
          name: 'AssetDetail',
          params: { pathName: asset.slug }
        }"
        class="cell grid__item medium-3 card"
      >
        <div class="card__title">{{ asset.title }}</div>
        <figure class="card__image">
          <ul class="card__tags">
            <li>{{ asset.category.name }}</li>
          </ul>
          <img
            v-if="asset.preview_image"
            :src="asset.preview_image.url"
            :alt="asset.title"
          />
        </figure>
      </router-link>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'RecentAssets',
  props: ['contentItem'],
  data () {
    return {
      assets: []
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/assets?offset=0&limit=4`)
      .then(response => {
        this.assets = response.data
      })
  }
}
</script>

<style scoped lang="scss"></style>
