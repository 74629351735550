<template>
  <div class="grid-x grid-margin-x grid-margin-y large-up-3">
    <div class="cell" v-for="(item, index) in contentItem.items" :key="index">
      <div class="card card-colors">
        <div
          class="card-colors__header"
          :style="backgroundColor('#' + item.hex)"
        >
          <span>
            A a
            <small v-if="item.dark_is_correct == true">{{
              $t("app.good")
            }}</small>
            <small v-else>{{ $t("app.wrong") }}</small>
          </span>
          <span class="color--white">
            A a
            <small v-if="item.light_is_correct == true">{{
              $t("app.good")
            }}</small>
            <small v-else>{{ $t("app.wrong") }}</small>
          </span>
        </div>
        <div class="card__section">
          <h4 class="medium">{{ item.title }}</h4>
          <div class="spacer--15"></div>
          <div class="grid-x grid-margin-x medium-up-2 card-colors__color-list">
            <div class="cell" v-if="item.hex">
              <span
                class="card-colors__color clipboard-link"
                @click="doCopy('#' + item.hex)"
              >
                <h6>{{ $t("app.hex") }}</h6>
                <span>#{{ item.hex }}</span>
              </span>
            </div>
            <div class="cell" v-if="item.rgb">
              <span
                class="card-colors__color clipboard-link"
                @click="doCopy(item.rgb)"
                v-clipboard:copy="item.rgb"
              >
                <h6>{{ $t("app.rgb") }}</h6>
                <span>{{ item.rgb }}</span>
              </span>
            </div>
            <div class="cell" v-if="item.cmyk">
              <span
                class="card-colors__color clipboard-link"
                @click="doCopy(item.cmyk)"
                v-clipboard:copy="item.cmyk"
              >
                <h6>{{ $t("app.cmyk") }}</h6>
                <span>{{ item.cmyk }}</span>
              </span>
            </div>
            <div class="cell" v-if="item.pantone">
              <span
                class="card-colors__color clipboard-link"
                @click="doCopy(item.pantone)"
              >
                <h6>{{ $t("app.pantone") }}</h6>
                <span>{{ item.pantone }}</span>
              </span>
            </div>
            <div class="cell" v-if="item.ral">
              <span
                class="card-colors__color clipboard-link"
                @click="doCopy(item.ral)"
              >
                <h6>{{ $t("app.ral") }}</h6>
                <span>{{ item.ral }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardColorList',
  props: ['contentItem'],
  methods: {
    backgroundColor (item) {
      return 'background-color:' + item
    },
    doCopy: function (color) {
      this.$copyText(color)
      this.$notify({
        group: 'custom-template',
        title: false,
        position: 'bottom left',
        text: this.$t('notify.color', {
          value: color
        })
      })
    }
  }
}
</script>
