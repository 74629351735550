<template>
  <card-item class="relative" :title="contentItem.title" icon="icon-downloads" :primary="true">
    <div v-for="(item, index) in contentItem.items" :key="index">
      <sidebar-card-download-file class="download-item" :item="item"/>
    </div>
  </card-item>
</template>

<script>
import SidebarCardDownloadFile from './SidebarCardDownloadFile'

export default {
  name: 'SidebarCardDownloadFileList',
  props: ['contentItem'],
  data () {
    return {
      visibleDownload: null
    }
  },
  components: {
    SidebarCardDownloadFile
  }
}
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}

.download-item {
  margin-bottom: 5px;
}
</style>
