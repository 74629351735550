<template>
  <div class="card card__section card-ratio">
    <div v-html="contentItem.text"></div>
    <div class="spacer--30"></div>
    <div class="grid-x grid-padding-x">
      <div
        class="cell shrink"
        v-for="(item, index) in contentItem.items"
        :key="index"
        :style="itemWidth(item.percentage)"
      >
        <div
          class="card-ratio__color has-border"
          :style="backgroundColor(item.title)"
        ></div>
        <p v-html="item.percentage + '%'"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardColorRatio',
  props: ['contentItem'],
  methods: {
    backgroundColor (item) {
      return 'background-color:#' + item
    },
    itemWidth (item) {
      return 'width:' + item + '%'
    }
  }
}
</script>
