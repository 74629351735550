<template>
  <div @mouseover="modalVisible=true" @mouseleave="modalVisible=false">
    <download-button
      type="pages"
      cssClass="card__button"
      :url="item.file"
      :title="fileName(item)"
      :icon="true"
    ></download-button>
    <download-modal :download="item" :visible="modalVisible"></download-modal>
  </div>
</template>

<script>
import helpers from '@/helpers'
import DownloadModal from './DownloadModal'
import DownloadButton from '@/views/common/components/buttons/DownloadButton'

export default {
  name: 'SidebarCardDownloadFile',
  mixins: [helpers],
  props: ['item'],
  data () {
    return {
      modalVisible: false
    }
  },
  methods: {
    fileName (item) {
      return item.title !== null ? item.title : this.readableFileName(item.file)
    }
  },
  components: {
    DownloadModal,
    DownloadButton
  }
}
</script>
