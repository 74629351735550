<template>
  <card-item :title="contentItem.title" icon="icon-downloads">
    <div v-for="(item, key) in contentItem.items" :key="key">
      <router-link
        :to="{
          name: 'AssetDetail',
          params: { pathName: item.slug }
        }"
        v-if="item.slug"
        class="card__button"
      >
        {{ item.title }} <i class="icon-link"></i>
      </router-link>
    </div>
  </card-item>
</template>

<script>
export default {
  name: 'SidebarCardRelatedAssets',
  props: ['contentItem']
}
</script>
