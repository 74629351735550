<template>
  <div class="card">
    <div class="card__section" v-html="contentItem.text"></div>
    <hr />
    <div class="grid-x medium-up-3">
      <div v-for="(item, index) in contentItem.items" :key="index" class="cell">
        <div class="card__title card__title--icon-left">
          <h5 class="medium">
            <i class="icon-wrong color--error" style="top: 26px;"></i>
            {{ item.title }}
          </h5>
        </div>
        <div class="card__section">
          <div class="text-center">
            <img class="dashed-border" :src="item.image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWrongUsageRepeater',
  props: ['contentItem']
}
</script>
<style scoped>
.dashed-border {
  border: 2px dashed var(--primary-color);
}
</style>
