<template>
  <div>
    <div class="grid-x grid-margin-x">
      <router-link
        :to="{
          name: 'BrandList'
        }"
        class="cell auto button--card has-arrow"
        >{{ $t("navigation.brand") }}</router-link
      >
      <router-link
        :to="{
          name: 'Calendar'
        }"
        class="cell auto button--card has-arrow"
        >{{ $t("navigation.planning") }}</router-link
      >
      <router-link
        :to="{
          name: 'AssetList'
        }"
        class="cell auto button--card has-arrow"
        >{{ $t("navigation.assets") }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardButtons',
  props: ['contentItem']
}
</script>

<style scoped lang="scss"></style>
