<template>
  <div>
    <div class="chapter-title" v-if="contentItem.title">
      <h2>{{ contentItem.title }}</h2>
    </div>
    <div class="spacer--20" v-if="contentItem.title"></div>
    <div
      class="card card-rules"
      v-for="(item, index) in contentItem.items"
      :key="index"
    >
      <div class="card__section">
        <div class="grid-x align-middle">
          <div class="cell auto">
            <h5 class="medium">{{ item.title }}</h5>
          </div>
          <div class="cell shrink">
            <span
              class="card-rules__color-container"
              v-for="(color, color_index) in contentItem.colors"
              :key="color_index"
            >
              <span
                class="card-rules__color has-border"
                :style="{ backgroundColor: '#' + color.value }"
              ></span>
            </span>
          </div>
        </div>
      </div>

      <div
        class="card__section--small"
        v-for="(type, type_index) in item.types"
        :key="type_index"
      >
        <div class="grid-x align-middle">
          <div class="cell auto">
            <p class="mb-0">{{ type.title }}</p>
          </div>
          <div class="cell shrink">
            <span
              class="card-rules__icon"
              :class="check.checkbox == '1' ? 'is-correct' : ''"
              v-for="(check, check_index) in type.checks"
              :key="check_index"
            >
              <i
                :class="check.checkbox == '1' ? 'icon-checkmark' : 'icon-close'"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardColorUsage',
  props: ['contentItem']
}
</script>

<style scoped lang="scss">
.card__section--small {
  border-top: 1px solid $ui-line-color;
}
</style>
