<template>
  <div class="card">
    <div
      v-for="(item, index) in contentItem.items"
      :key="index"
      class="card__inner-container"
    >
      <div class="grid-x" v-if="item.type == 'text'">
        <div class="cell auto">
          <div class="card__section" v-html="item.text"></div>
        </div>
      </div>

      <div class="grid-x" v-else-if="item.type == 'text-text'">
        <div class="cell" :class="'medium-' + columnWidth(item.columns)">
          <div class="card__section" v-html="item.text"></div>
        </div>
        <div class="cell auto">
          <div class="card__section" v-html="item.text_col_two"></div>
        </div>
      </div>

      <div class="grid-x" v-else-if="item.type == 'text-text-text'">
        <div class="cell medium-4 auto">
          <div class="card__section" v-html="item.text"></div>
        </div>
        <div class="cell auto">
          <div class="card__section" v-html="item.text_col_two"></div>
        </div>
        <div class="cell auto">
          <div class="card__section" v-html="item.text_col_three"></div>
        </div>
      </div>
      <div class="grid-x" v-else-if="item.type == 'text-image'">
        <div class="cell" :class="'medium-' + columnWidth(item.columns)">
          <div class="card__section" v-html="item.text"></div>
        </div>
        <div class="cell auto">
          <div :class="[item.imagePadding == '1' ? 'card__section' : '']">
            <img :src="item.image" alt=""/>
          </div>
        </div>
      </div>

      <div class="grid-x" v-else-if="item.type == 'image-text'">
        <div class="cell" :class="'medium-' + columnWidth(item.columns)">
          <div :class="[item.imagePadding == '1' ? 'card__section' : '']">
            <img :src="item.image" alt=""/>
          </div>
        </div>
        <div class="cell auto">
          <div class="card__section" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardContentRepeater',
  props: ['contentItem'],
  methods: {
    columnWidth (item) {
      if (item === '1-2') {
        return '4'
      } else if (item === '2-1') {
        return '8'
      } else {
        return '6'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.card__inner-container {
  + .card__inner-container {
    border-top: 1px solid $ui-line-color;
  }
}
</style>
