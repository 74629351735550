<template>
  <div>
    <h2 class="medium">{{ $t("updates.latest_updates") }}</h2>
    <div class="spacer--30"></div>
    <div class="grid-x grid-margin-x small-up-1 medium-up-2 large-up-4">
      <router-link
        v-for="(item, index) in postsWithCategory"
        :key="index"
        :to="{
          name: 'UpdatesDetail',
          params: { category: item.categories[0].slug, pathName: item.slug }
        }"
        class="card cell"
      >
        <div
          v-if="item.post_extend.banner_medium_url"
          class="updates-list__image"
          :style="itemBackground(item.post_extend.banner_medium_url)"
        ></div>
        <div class="card__section update-author">
          <div class="grid-x grid-margin-x align-middle">
            <div class="cell shrink update-author__image__wrapper" v-if="item.author">
              <div
                class="update-author__image"
                :style="itemBackground(item.author.avatar.url)"
              ></div>
            </div>

            <div class="cell auto update-author__details-wrapper">
              <span class="update-author__name" v-if="item.author"
                >{{ item.author.name }} {{ item.author.surname }}</span
              >
              <span class="update-author__function" v-if="item.author">{{
                item.author.function
              }}</span>
              <span class="update-author__badge"
                ><i class="icon-blog-time"></i>
                {{ item.published_at | formatDate }}</span
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="card__section">
          <div class="updates-list__title">{{ item.title }}</div>
          <div
            v-if="item.excerpt"
            class="updates-list__content"
            v-html="item.excerpt"
          ></div>
          <p class="updates-list__more">Read more</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import moment from 'moment'

export default {
  name: 'UpdateList',
  props: ['contentItem'],
  data () {
    return {
      updates: []
    }
  },
  filters: {
    formatDate (value) {
      return moment(String(value)).format('DD-MM-YYYY')
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/blog/posts/abrnd`)
      .then(response => {
        this.updates = response.data
      })
  },
  computed: {
    postsWithCategory: function () {
      return this.updates
        .filter(i => {
          return i.categories.length > 0
        })
        .slice(0, 4)
    }
  },
  methods: {
    itemBackground (item) {
      return "background-image:url('" + item + "');"
    }
  }
}
</script>

<style scoped lang="scss"></style>
