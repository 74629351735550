<template>
  <div class="grid-x grid-margin-x card-list">
    <div
      class="cell medium-4 card"
      v-for="(item, index) in contentItem.items"
      :key="index"
    >
      <div class="card__title" v-html="item.title"></div>
      <div class="card__image">
        <img :src="item.image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardImageList',
  props: ['contentItem']
}
</script>
