<template>
  <div class="card">
    <div class="card__section" v-html="contentItem.text"></div>

    <div v-if="contentItem.duration || contentItem.priority != ''">
      <hr/>
      <div class="card__section card__intro">
        <div class="grid-x">
          <div
            class="cell shrink pr-20 card__intro-duration"
            v-if="contentItem.duration"
          >
            <p class="mb-0">
              <span v-if="contentItem.duration === '1'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
                {{ $t('states.simple') }}
              </span>
              <span v-else-if="contentItem.duration === '2'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
                {{ $t('states.easy') }}
              </span>
              <span v-else-if="contentItem.duration === '3'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
                {{ $t('states.normal') }}
              </span>
              <span v-else-if="contentItem.duration === '4'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
                {{ $t('states.complex') }}
              </span>
              <span v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
                {{ $t('states.expert') }}
              </span>
            </p>
          </div>
          <div class="cell shrink" v-if="contentItem.priority">
            <p class="mb-0">
              <i
                class="icon-priority"
                :class="priorityColor(contentItem.priority)"
              ></i>
              {{ contentItem.priority }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardIntro',
  props: ['contentItem'],
  methods: {
    priorityColor (item) {
      if (item === 'high') {
        return 'color--error'
      } else if (item === 'medium') {
        return 'color--warning'
      } else {
        return 'color--valid'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $ui-line-color;
}

.a,
.b {
  fill: none;
  stroke-linecap: round;
  stroke-width: 3px;
}

.a {
  stroke: #454545;
}

.b {
  stroke: $ui-line-color;
}
</style>
