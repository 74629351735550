var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"grid-x grid-margin-x"},[_c('router-link',{staticClass:"cell auto button--card has-arrow",attrs:{"to":{
        name: 'BrandList'
      }}},[_vm._v(_vm._s(_vm.$t("navigation.brand")))]),_c('router-link',{staticClass:"cell auto button--card has-arrow",attrs:{"to":{
        name: 'Calendar'
      }}},[_vm._v(_vm._s(_vm.$t("navigation.planning")))]),_c('router-link',{staticClass:"cell auto button--card has-arrow",attrs:{"to":{
        name: 'AssetList'
      }}},[_vm._v(_vm._s(_vm.$t("navigation.assets")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }