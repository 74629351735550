<template>
  <div class="page__inner-content">
    <component
      v-for="(contentItem, index) in contentItems"
      :key="index"
      v-bind:is="getComponent(contentItem._group)"
      :contentItem="contentItem"
    ></component>
  </div>
</template>

<script>
import cardColorGradient from '../../brand/components/view/content/CardColorGradient'
import cardColorList from '../../brand/components/view/content/CardColorList'
import cardColorRatio from '../../brand/components/view/content/CardColorRatio'
import cardColorUsage from '../../brand/components/view/content/CardColorUsage'
import cardContent from '../../brand/components/view/content/CardContent'
import cardContentRepeater from '../../brand/components/view/content/CardContentRepeater'
import cardDownloadList from '../../brand/components/view/content/CardDownloadList'
import cardImageList from '../../brand/components/view/content/CardImageList'
import cardIntro from '../../brand/components/view/content/CardIntro'
import cardList from '../../brand/components/view/content/CardList'
import cardWrongUsageRepeater from '../../brand/components/view/content/CardWrongUsageRepeater'
import chapterTitle from '../../brand/components/view/content/ChapterTitle'
import dashboardButtons from '../../brand/components/view/content/DashboardButtons'
import pageBanner from '../../brand/components/view/content/PageBanner'
import recentAssets from '../../brand/components/view/content/RecentAssets'
import updateList from '../../brand/components/view/content/UpdateList'
import cardDownloadFileList from '../../brand/components/view/sidebar/SidebarCardDownloadFileList'
import cardExampleList from '../../brand/components/view/sidebar/SidebarCardExampleList'
import cardLiteratureList from '../../brand/components/view/sidebar/SidebarCardLiteratureList'
import cardRelatedAssets from '../../brand/components/view/sidebar/SidebarCardRelatedAssets'
import splitBanner from '../../brand/components/view/content/SplitBanner'
import milestoneList from '../../brand/components/view/content/MilestoneList'

export default {
  name: 'ViewContentRepeater',
  props: {
    contentItems: Array
  },
  methods: {
    getComponent (groupName) {
      if (groupName === 'newsList') return 'updateList'
      return groupName
    }
  },
  components: {
    cardColorGradient,
    cardColorList,
    cardColorRatio,
    cardColorUsage,
    cardContent,
    cardContentRepeater,
    cardDownloadList,
    cardImageList,
    cardIntro,
    cardList,
    cardWrongUsageRepeater,
    chapterTitle,
    pageBanner,
    recentAssets,
    updateList,
    dashboardButtons,
    cardDownloadFileList,
    cardExampleList,
    cardLiteratureList,
    cardRelatedAssets,
    splitBanner,
    milestoneList
  }
}
</script>
