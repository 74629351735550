<template>
  <transition>
    <div class="window" v-if="visible">
      <card-item class="drop-shadow">
        <ul class="card__tags">
          <li>{{ extension }}</li>
        </ul>
        <figure class="preview">
          <img v-if="canPreview && visible" :src="download.file"/>
          <small v-else>{{ $t('app.preview_not_available') }}</small>
        </figure>
      </card-item>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'DownloadModal',
  props: ['download', 'visible'],
  computed: {
    extension () {
      return this.download.file.split('.').pop().toUpperCase()
    },
    canPreview () {
      const supported = ['svg', 'png', 'jpg', 'webp']
      return supported.includes(this.extension.toLowerCase())
    }
  }
}
</script>

<style scoped lang="scss">
.window {
  position: absolute;
  z-index: 999;
  width: 420px;
  left: -410px;
  top: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  &.v-enter {
    opacity: 0;
    left: -400px;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
    left: -420px;
  }

  .drop-shadow {
    box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.2);
  }

  .card__tags {
    position: unset;
  }

  .preview {
    padding-top: 10px;
  }

  hr {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: unset;
  }

  .card__button {
    float: right;
    background: var(--button-primary-color);
    color: var(--button-primary-text-color);
    display: inline-block;
    padding: 15px 20px;
    padding-right: 40px;
    height: auto;
    margin: 0;

    &:hover {
      color: var(--button-primary-text-color);

      &:after {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

small {
  font-size: 11px;
}
</style>
