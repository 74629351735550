<template>
  <div class="grid-y grid-margin-y">
    <div class="cell" v-for="(item, index) in contentItem.items" :key="index">
      <div class="milestone" :class="getClass(item.layout, item.size)" :style="itemBackground(item.file)">
        <div class="small-6 card__section milestone__content">
          <span class="year">{{ item.year }}</span>
          <hr/>
          <h1>{{ item.title }}</h1>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'MilestoneList',
  props: ['contentItem'],
  methods: {
    itemBackground (file) {
      return 'background-image:url(\'' + file + '\');'
    },
    getClass (layout, size) {
      const classes = []
      if (layout === 'right') {
        classes.push('layout__right')
      } else if (layout === 'left') {
        classes.push('layout__left')
      }
      if (size === 'xsmall') {
        classes.push('size__xsmall')
      } else if (size === 'small') {
        classes.push('size__small')
      } else if (size === 'medium') {
        classes.push('size__medium')
      } else if (size === 'large') {
        classes.push('size__large')
      } else if (size === 'xlarge') {
        classes.push('size__xlarge')
      }
      return classes
    }
  }
}
</script>

<style scoped lang="scss">
.milestone {
  background: var(--primary-color);
  border-radius: $global-radius;
  border: none;
  padding: 5vw;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;

  span {
    font-size: 3vh;
  }

  hr {
    margin: calc(10px) 0 calc(15px) 0;
  }

  &__content {
    background: $white;
    border-radius: $global-radius;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: calc(50% - 5vw);

    h1 {
      line-height: calc(25px);
      padding-bottom: calc(15px);
    }

    p {
      line-height: calc(16px);
    }
  }
}

.layout {
  &__right {
    .milestone__content {
      right: 0;
      margin-right: 5vw;
    }
  }

  &__left {
    .milestone__content {
      left: 0;
      margin-left: 5vw;
    }
  }
}

.size {
  &__xsmall {
    min-height: 25vh;
  }

  &__small {
    min-height: 35vh;
  }

  &__medium {
    min-height: 50vh;
  }

  &__large {
    min-height: 68vh;
  }

  &__xlarge {
    min-height: 79vh;
  }
}
</style>
