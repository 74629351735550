<template>
  <div class="grid-x grid-margin-x card-list">
    <div
      v-for="(item, index) in contentItem.items"
      :key="index"
      class="cell medium-4 card"
    >
      <div class="card__section">
        <div class="grid-x">
          <div class="cell auto">
            <h5 class="medium">{{ item.title }}</h5>
          </div>
          <div class="cell shrink">
            <dropdown-item
              ref="dropdown"
              :isIcon="false"
              :unscroll="'main'"
              :align="'left'"
              :width="500"
              :y="27"
              :x="27"
            >
              <template slot="btn">
                <i class="icon-download color--primary"></i>
              </template>
              <template slot="body">
                <div class="download-dropdown">
                  <ul>
                    <li
                      v-for="(download, sub_index) in item.downloads"
                      :key="sub_index"
                    >
                      <download-button
                        type="pages"
                        :url="download.filepath"
                        :title="readableFileName(download.file_name)"
                      ></download-button>
                    </li>
                  </ul>
                </div>
              </template>
            </dropdown-item>
          </div>
        </div>
      </div>
      <div class="card__section download-dropdown__content">
        <div class="text-center">
          <img :src="item.image" :alt="item.title"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers'

export default {
  name: 'CardDownloadList',
  props: ['contentItem'],
  mixins: [helpers],
  methods: {
    getExtension (data) {
      const ext = /^.+\.([^.]+)$/.exec(data)
      return ext == null ? '' : ext[1]
    }
  }
}
</script>
