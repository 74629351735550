<template>
  <div
    :class="[
      contentItem.size,
      contentItem.white_content === true ? 'color--white' : ''
    ]"
    :style="itemBackground"
    class="page-banner background--center"
  >

    <div
      class="grid-x align-middle"
      :class="contentItem.alignment === 'right' ? 'align-right' : ''"
    >
      <div
        class="cell content"
        v-html="contentItem.text"
        :class="contentItem.alignment === 'fullWidth' ? 'auto' : 'small-12 xlarge-7'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBanner',
  props: ['contentItem'],
  computed: {
    itemBackground () {
      if (this.contentItem.type === 'image') {
        return 'background-image:url(\'' + this.contentItem.image + '\');'
      } else {
        return 'background-color:#' + this.contentItem.color + ';'
      }
    }
  }
}
</script>
