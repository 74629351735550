<template>
  <div class="chapter-title">
    <h2 v-html="contentItem.title"></h2>
  </div>
</template>

<script>
export default {
  name: 'ChapterTitle',
  props: ['contentItem']
}
</script>

<style scoped lang="scss"></style>
