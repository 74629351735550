<template>
  <card-item :title="contentItem.title" icon="icon-literature">
    <div v-for="(item, index) in contentItem.items" :key="index">
      <a
        v-if="item.type == 'link'"
        class="card__button"
        :href="item.link"
        target="_blank"
      >{{ item.title }} <i class="icon-examples"></i
      ></a>
      <download-button
        v-if="item.type == 'file'"
        type="pages"
        cssClass="card__button"
        :url="item.file"
        :title="item.title"
        :icon="true"
      ></download-button>
    </div>
  </card-item>
</template>

<script>
export default {
  name: 'SidebarCardLiteratureList',
  props: ['contentItem'],
  methods: {}
}
</script>
