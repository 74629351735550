<template>
  <div class="card card__section" v-html="contentItem.text"></div>
</template>

<script>
export default {
  name: 'CardContent',
  props: ['contentItem']
}
</script>

<style scoped lang="scss"></style>
